import type { NextPage } from 'next';
import Login from '@/components/User/Login';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

const LoginPage: NextPage = () => {
  return (
    <>
      <Header />
      <Login />
      <Footer />
    </>
  )
}

export default LoginPage
